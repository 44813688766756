export const tituloPrincipal = {
  titulo: 'ALUMBRADO PUBLICO DE VILLA NUEVA, S.A',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
      width: 15,
    },
  },
}

export const tituloSecundario = {
  titulo: 'ADMINISTRACIÓN DE GATEWAY',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE ADMINISTRACIÓN DE GATEWAY',
  style: {
    font: {
      bold: true,
      size: 10,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const tituloDescarga = 'REPORTE GENERAL DE ADMINISTRACIÓN DE GATEWAY'
export function getColumns() {
  return [
    {
      label: 'Nombre',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Serie',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Protocolo',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Red',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Internet',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Coordenadas',
      width: 40,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Protección Corriente',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Switch Remoto',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
  ]
}

export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const item = {
      nombre: {
        value: row.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        }, // style que va tener la fila en el reporte
      },
      serie: {
        value: row.serie,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      protocolo: {
        value: row.protocolo,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      red: {
        value: row.red,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      internet: {
        value: row.internet.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      coordenadas: {
        value: (row.latitud && row.longitud) ? `${row.latitud}, ${row.longitud}` : '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      proteccion: {
        value: row.proteccion.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      switch: {
        value: row.switch,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
    }
    listado.push(item)
  })

  return listado
}
